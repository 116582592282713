import React from 'react';
import { css, useTheme } from '@emotion/react';

const searchWidgetContainer = css`
  button {
    text-align: center;
  }
`;

const version = process.env.NEXT_PUBLIC_SEARCH_WIDGET_VERSION;
const sourceUrl = process.env.NEXT_PUBLIC_SEARCH_WIDGET_SOURCE_URL;
const funnelUrl = process.env.NEXT_PUBLIC_SEARCH_WIDGET_FUNNEL_URL;
const growthbookEndpoint =
  process.env.NEXT_PUBLIC_SEARCH_WIDGET_GROWTHBOOK_ENDPOINT;
const growthbookDevMode =
  process.env.NEXT_PUBLIC_SEARCH_WIDGET_GROWTHBOOK_DEV_MODE;
const coreApiUrl = process.env.NEXT_PUBLIC_SEARCH_WIDGET_CORE_API_URL;

const SearchWidget = () => {
  const { colors } = useTheme();

  return (
    <div css={searchWidgetContainer}>
      <div
        className="searchbar"
        data-widget-host="habitat"
        data-prop-lang="es-MX"
        data-prop-compact-height=""
        data-prop-version={version}
        data-prop-primary-color={colors.primary}
        data-prop-accent-color={colors.accent}
        data-prop-source-url={sourceUrl}
        data-prop-funnel-url={funnelUrl}
        data-prop-allow-hybrid-trip="false"
        data-prop-brand="rollbits"
        data-prop-allow-fetch="true"
        data-prop-max-days-search="60"
        data-prop-display-type="only_terminal"
        data-prop-passengers-dropdown="false"
        data-prop-autocomplete-type="select"
        data-prop-opt-in-return="false"
        data-prop-force-https="true"
        data-prop-growthbook-standalone="true"
        data-prop-growthbook={growthbookEndpoint}
        data-prop-growthbook-dev={growthbookDevMode}
        data-prop-profile-url={coreApiUrl}
        data-prop-profile-enabled="true"
        data-prop-profile-autocomplete="true"
      />
    </div>
  );
};

export default SearchWidget;
